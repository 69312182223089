







































import Vue from "vue";
import Component from "vue-class-component";
import { Getter } from "vuex-class";

import {
  api as auditProgramOverviewApi,
  Getters as AuditProgramOverviewGetters,
} from "@/store/modules/auditProgramOverview";

import AAuditProgramCard from "@/components/widgets/AAuditProgramCard.vue";
import AAuditProgramGroupCard from "@/components/widgets/AAuditProgramGroupCard.vue";

@Component({
  components: {
    AAuditProgramCard,
    AAuditProgramGroupCard,
  },
})
export default class AAuditProgramGrid extends Vue {
  @Getter(auditProgramOverviewApi.getters.getProgramCardData, {
    namespace: auditProgramOverviewApi.namespace,
  })
  programData!: AuditProgramOverviewGetters["getProgramCardData"];

  @Getter(auditProgramOverviewApi.getters.getOrganizationUnitCardData, {
    namespace: auditProgramOverviewApi.namespace,
  })
  organizationUnits!: AuditProgramOverviewGetters["getOrganizationUnitCardData"];
}
