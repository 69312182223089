
































































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import DoughnutChart from "@/components/charts/DoughnutChart";
import { AuditProgramCardData } from "@/store/modules/auditProgramOverview/types";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { ROUTE_NAMES } from "@/routenames";
import { Dictionary } from "lodash";
import AUserChip from "@/components/snippets/AUserChip.vue";

@Component({
  components: {
    DoughnutChart,
    AUserChip,
  },
})
export default class AAuditProgramCard extends Vue {
  readonly chartHeight: number = 150;
  readonly chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    animation: false,
  };

  @Prop({
    type: Object,
    required: true,
  })
  readonly content!: AuditProgramCardData;

  get dataCollection(): Chart.ChartData {
    return this.content.chartData;
  }

  get totalAuditCount(): number {
    return this.content.amountInCurrentYear;
  }

  get responsible(): IUserRef | null {
    return this.content.info.responsible;
  }

  get title(): string {
    return this.$ft(this.content.info.name);
  }

  openAuditProgram() {
    const params: Dictionary<string> = {
      auditProgramId: this.content.info.id,

      year: String(this.content.currentYear),
    };
    if (this.content.info.organizationUnitId) {
      params["organizationUnitId"] = this.content.info.organizationUnitId;
    }
    this.$router.push({
      name: this.content.info.organizationUnitId
        ? ROUTE_NAMES.AUDITMATRIX_UNIT_PROGRAM_FILTERED
        : ROUTE_NAMES.AUDITMATRIX_PROGRAM_FILTERED,
      params,
    });
  }
}
