


























































































import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

import DoughnutChart from "@/components/charts/DoughnutChart";
import { OrganizationUnitCardData } from "@/store/modules/auditProgramOverview/types";
import { IUserRef } from "@auditcloud/shared/lib/types/UserRef";
import { ROUTE_NAMES } from "@/routenames";
import { Dictionary } from "lodash";
import AUserChip from "@/components/snippets/AUserChip.vue";
import { colorForIdx } from "@auditcloud/shared/lib/constants";
import { colors } from "@/components/theme";

@Component({
  components: {
    DoughnutChart,
    AUserChip,
  },
})
export default class AAuditProgramGroupCard extends Vue {
  @Prop({
    type: Object,
    required: true,
  })
  readonly content!: OrganizationUnitCardData;

  @Prop({
    type: String,
    default: "320",
  })
  readonly size!: string;

  get responsible(): IUserRef | null {
    return this.content.responsible;
  }

  get title(): string {
    return this.$ft(this.content.name);
  }

  get auditProgramCount(): number {
    return this.content.auditProgramCount;
  }

  get imageUrl(): string | null {
    return this.content.imageUrl ?? null;
  }

  get backgroundImageStyle() {
    const { imageUrl } = this;

    if (imageUrl) {
      const linearGradient =
        "linear-gradient(180deg,rgba(0, 0, 0, 0) 67.19%,rgba(0, 0, 0, 0.8) 100%)";
      const url = `url(${imageUrl}) center no-repeat`;
      const background = linearGradient + "," + url;

      return {
        background,
        backgroundSize: "cover",
      };
    } else {
      return {
        backgroundColor: colors["primary"],
      };
    }
  }

  openProgramGroup() {
    const params: Dictionary<string> = {
      organizationUnitId: this.content.id,
    };
    this.$router.push({
      name: ROUTE_NAMES.AUDIT_PROGRAMS,
      params,
    });
  }
}
