import Vue from "vue";
import { Doughnut, mixins } from "vue-chartjs";
const { reactiveProp } = mixins;

import { Component, Prop } from "vue-property-decorator";
import { buildDefaultChartData } from "./utils";

@Component({
  mixins: [reactiveProp],
  extends: Doughnut,
})
export default class DoughnutChart extends Vue<Doughnut> {
  constructor(...props: any[]) {
    super(...props);
  }

  @Prop({
    type: Object,
  })
  options!: Chart.ChartOptions;

  @Prop({
    type: Object,
    default: buildDefaultChartData,
  })
  chartData!: Chart.ChartData;

  mounted() {
    // this.chartData is created in the mixin.
    // If you want to pass options please create a local options object
    this.renderChart(this.chartData, this.options);
  }
}
